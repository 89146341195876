.MuiTableHead-root th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.MuiTableHead-root th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.MuiTableRow-root #cell:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-left: 1px solid #c5c5c5;
}

.MuiTableRow-root #cell:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-right: 1px solid #c5c5c5;
}

.MuiTableRow-root #cell {
  border-top: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  padding: 5px;
}

#cell-error {
  border-radius: 12px;
  padding: 4px 12px;
}
#cell-error p {
  color: #F86060;
  font: normal normal 600 14px Montserrat;
}
