@font-face {
  font-family: 'SFProDisplay';
  src: local('SFProDisplay'), url('./fonts/SF-Pro-Display-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'SFProDisplayBold';
  src: local('SFProDisplay'), url('./fonts/FontsFree-Net-SFProDisplay-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'SFProDisplayMedium';
  src: local('SFProDisplay'), url('./fonts/SF-Pro-Display-Medium.otf') format('truetype');
}


body {
  background-color: #f9fafc;
}
